import React from "react";
import { MainPage } from "./components/MainPage"
import "bootstrap/dist/css/bootstrap.min.css"
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

// localStorage.debug = 'looker:chatty:*'

export const App = () => {
    const msalInstance = new PublicClientApplication(msalConfig);
    return <div>
        <MsalProvider instance={msalInstance}>
            <MainPage />
        </MsalProvider>
    </div>;
}