import React, { useCallback } from "react";
import { LookerEmbedSDK } from "@looker/embed-sdk";
import { EmbedContainer } from "./EmbedContainer";

let AUTH_PREFIX = "";
if (process.env.NODE_ENV === "development") {
  AUTH_PREFIX = "http://127.0.0.1:8000";
}

export const EmbeddedDashboard = ({ id, token, unauthFn }) => {
  const [dashboard, setDashboard] = React.useState();
  const setupDashboard = (dashboard) => {
    setDashboard(dashboard);
  };

  // const location_filters = {
  //   west: "West",
  //   midwest: "Midwest",
  //   northeast: "Northeast",
  //   south: "South",
  //   noncont: "Non-Continental US",
  //   world: "Outside the US",
  // };

  const embedCtrRef = useCallback((el) => {
    const hostUrl = "westernforest.cloud.looker.com";
    // const location_filter = filter ? { "Location Group": location_filters[filter] } : {};
    if (el && hostUrl) {
      el.innerHTML = "";
      LookerEmbedSDK.init(hostUrl, {
        url: AUTH_PREFIX + "/auth",
        headers: [{ name: "Authorization", value: "bearer " + token }],
      });
      LookerEmbedSDK.createDashboardWithId(id)
        .appendTo(el)
        .withNext()
        .withTheme("wfp")
        // .withFilters({ "Location Group": "West" })
        // .withFilters(location_filter)
        .build()
        .connect()
        .then(setupDashboard)
        .catch((error) => {
          console.error("Connection Error", error);
          if (unauthFn) {
            unauthFn()
          }
        });
    }
  }, []);

  return <EmbedContainer ref={embedCtrRef}></EmbedContainer>;
};
