import React from "react";
import useCookie, { getCookie, setCookie } from 'react-use-cookie';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { EmbeddedDashboard } from "./Dashboard";
import userIcon from "../assets/user-icon.png";

import clientLogo from "../assets/wfp-logo2.png"
import fastloopLogo from "../assets/Fastloop_logo.png"
import logOutLogo from "../assets/right-from-bracket-solid.svg"
import chevron from "../assets/chevron-down-grey.svg"
import { SignInButton } from "./SignInButton"
import { loginRequest } from '../authConfig';

let AUTH_PREFIX = "";
if (process.env.NODE_ENV === "development") {
  AUTH_PREFIX = "http://127.0.0.1:8000";
}

export const MainPage = () => {
  const [dashboardId, setDashboardId] = React.useState(10);
  const [activeLink, setActiveLink] = React.useState("outputsTotal");
  const [exploreId, setExploreId] = React.useState("");
  const [moreToggle, setMoreToggle] = React.useState(false)


  const userCookieSave = getCookie("userToken") || "";
  const [userCookie, setUserCookie] = useCookie("userToken", userCookieSave);

  const [token, setToken] = React.useState(userCookieSave);
  const [username, setUsername] = React.useState("");

  const niceNameCookieSave = getCookie("niceNameCookie") || "";
  const [niceNameCookie, setNiceNameCookie] = useCookie("niceNameCookie", niceNameCookieSave);
  const [niceName, setNiceName] = React.useState(niceNameCookie)
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const [msLogin, setMsLogin] = React.useState(true)

  function RequestProfileData() {
    if (accounts && accounts[0]) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          handleLoginMsf(response.idToken)
        });
    }
  }

  const [errmsg, setErrmsg] = React.useState("")

  const dashboards = {
    over: 38,
    revenue: 17,
    costs: 20,
    output: 24,
    outputsTotal: 10,
    outputsMfbm: 37,
    output_pe: 5,
    output_ls: 14,
    output_cp: 1,
    output_boc: 29,
    output_lc: 27,
    output_cd: 2,
    output_spc: 13,
    historyOverview: 19,
    historyRevenue: 17,
    historyCosts: 20,
  };

  const explores = {
    chat: "westernforestratedchat1",
    sent: "westernforest_rated_chats_sentiment",
  };

  const handleToggle = (e) => {
    setMoreToggle(!moreToggle);
  }

  const handleClick = (id, e) => {
    if (typeof id === "string") {
      setExploreId(id);
      setDashboardId(0);
    } else {
      setExploreId("");
      setDashboardId(id);
    }
    document.getElementById(activeLink).classList.remove("active");
    setActiveLink(e.target.id);
    document.getElementById(e.target.id).classList.add("active");
  };


  const logoutFn = () => {
    setToken("")
    setCookie("")
    setNiceNameCookie("")
    setUserCookie("")
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }

  React.useEffect(() => {
    RequestProfileData();
  }, [isAuthenticated])

  const handleLoginMsf = (mstoken) => {
    const userData = new FormData();
    userData.append("jwtToken", mstoken)

    fetch(AUTH_PREFIX + "/msf", {
      method: "POST",
      headers: {
        "access-control-request-headers": "*",
      },
      body: JSON.stringify({ "jwtToken": mstoken }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.detail) {
          setErrmsg(data.detail)
        } else {
          setErrmsg("")
        }
        setToken(data.token);
        setUserCookie(data.token)
        setUsername(data.user);
        setNiceNameCookie(data.nice_name)
        setNiceName(data.nice_name)
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const userData = new FormData();
    userData.append("username", e.target.form["0"].value);
    userData.append("password", e.target.form["1"].value);
    fetch(AUTH_PREFIX + "/token", {
      method: "POST",
      headers: {
        "access-control-request-headers": "*",
      },
      body: userData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.detail) {
          setErrmsg(data.detail)
        } else {
          setErrmsg("")
        }
        setToken(data.token);
        setUserCookie(data.token)
        setUsername(data.user);
        setNiceNameCookie(data.nice_name)
        setNiceName(data.nice_name)
      });
  };
  return (
    <div>
      {" "}
      {token && token.length > 0 ? (
        <div className="entire-window">
          <header className="navbar sticky-top flex-md-nowrap p-0">
            <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="#">
              <img className="client-logo" src={clientLogo} />
            </a>
            <h1 className="client-title d-none d-xl-block">Weekly Value of Production (VOP)</h1>
            <div className="navbar-nav">
              <div className="nav-item text-nowrap user-link">
                <a className="nav-link px-3" href="#">
                  {/* {username} rm because added cookie handling of token */}
                  {/* WFP Saltair */}
                  User: {niceName}
                  <img className="user-icon" src={userIcon} />
                </a>
                <a className="nav-link px-3" href="#" onClick={logoutFn}>
                  <img alt="Log out" title="Log out" className="user-icon" src={logOutLogo} />
                </a>
              </div>
            </div>
          </header>
          <div className="container-fluid">
            <div className="row">
              <nav id="sidebarMenu" className="col-md-2 col-lg-1 sidebar d-md-block">
                <div className="position-sticky pt-3">
                  <ul className="nav flex-column">
                    <li className="nav-item">
                      <a
                        id="outputsTotal"
                        className="nav-link side-link active"
                        href="#outputsTotal"
                        onClick={(e) => handleClick(dashboards["outputsTotal"], e)}
                      >
                        <span data-feather="bar-chart-2" className="align-text-bottom"></span>
                        Outputs<br />($ Total)
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        id="outputsMfbm"
                        className="nav-link side-link"
                        href="#outputsMfbm"
                        onClick={(e) => handleClick(dashboards["outputsMfbm"], e)}
                      >
                        <span data-feather="shopping-cart" className="align-text-bottom"></span>
                        Outputs<br />($/Mfbm)
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        id="over"
                        className="nav-link side-link"
                        aria-current="page"
                        href="#over"
                        onClick={(e) => handleClick(dashboards["over"], e)}
                      >
                        <span data-feather="home" className="align-text-bottom"></span>
                        Overview
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        id="output"
                        className="nav-link side-link"
                        href="#output"
                        // onClick={(e) => handleClick(dashboards["output"], e)}
                        onClick={(e) => handleToggle(e)}
                      >
                        <span data-feather="shopping-cart" className="align-text-bottom"></span>
                        Output Details <span> {moreToggle ? (<img className="chevron-down" src={chevron} />) : (<img className="chevron-up" src={chevron} />)}</span>
                        {/* <br />
                        Output Details <span> {moreToggle ? '+' : '-' }</span> */}
                      </a>
                    </li>
                    <div className={`more-${moreToggle}`}>
                      {/* <li className="nav-item sub-nav-item">
                        <a
                          id="output2"
                          className="nav-link side-link"
                          href="#output2"
                          onClick={(e) => handleClick(dashboards["output"], e)}
                        >
                          <span data-feather="shopping-cart" className="align-text-bottom"></span>
                          Outputs
                        </a>
                      </li> */}
                      <li className="nav-item sub-nav-item">
                        <a
                          id="output_pe"
                          className="nav-link side-link"
                          href="#output_pe"
                          onClick={(e) => handleClick(dashboards["output_pe"], e)}
                        >
                          <span data-feather="shopping-cart" className="align-text-bottom"></span>
                          Production Entries
                        </a>
                      </li>

                      <li className="nav-item sub-nav-item">
                        <a
                          id="output_ls"
                          className="nav-link side-link"
                          href="#output_ls"
                          onClick={(e) => handleClick(dashboards["output_ls"], e)}
                        >
                          <span data-feather="shopping-cart" className="align-text-bottom"></span>
                          Lumber Sales Values
                        </a>
                      </li>

                      <li className="nav-item sub-nav-item">
                        <a
                          id="output_cp"
                          className="nav-link side-link"
                          href="#output_cp"
                          onClick={(e) => handleClick(dashboards["output_cp"], e)}
                        >
                          <span data-feather="shopping-cart" className="align-text-bottom"></span>
                          Chip Pricing
                        </a>
                      </li>

                      <li className="nav-item sub-nav-item">
                        <a
                          id="output_boc"
                          className="nav-link side-link"
                          href="#output_boc"
                          onClick={(e) => handleClick(dashboards["output_boc"], e)}
                        >
                          <span data-feather="shopping-cart" className="align-text-bottom"></span>
                          BOC FX Rates
                        </a>
                      </li>

                      <li className="nav-item sub-nav-item">
                        <a
                          id="output_lc"
                          className="nav-link side-link"
                          href="#output_lc"
                          onClick={(e) => handleClick(dashboards["output_lc"], e)}
                        >
                          <span data-feather="shopping-cart" className="align-text-bottom"></span>
                          Log Costs
                        </a>
                      </li>

                      <li className="nav-item sub-nav-item">
                        <a
                          id="output_cd"
                          className="nav-link side-link"
                          href="#output_cd"
                          onClick={(e) => handleClick(dashboards["output_cd"], e)}
                        >
                          <span data-feather="shopping-cart" className="align-text-bottom"></span>
                          Cost Drivers
                        </a>
                      </li>

                      <li className="nav-item sub-nav-item">
                        <a
                          id="output_spc"
                          className="nav-link side-link"
                          href="#output_spc"
                          onClick={(e) => handleClick(dashboards["output_spc"], e)}
                        >
                          <span data-feather="shopping-cart" className="align-text-bottom"></span>
                          Secondary Processing Costs
                        </a>
                      </li>
                    </div>
                  </ul>
                </div>
                <div className="powered-by-div">
                  <p className="powered-by-span"><img className="fastloop-logo" src={fastloopLogo} />
                  </p>
                </div>
              </nav>
              {/* {activeLink !== "over" && (
              <div className="row">
              <ul className="location-group">
                <li className="d-inline">
                  <a id="west" className="map-location" onClick={(e) => handleLocationChange(e)}>
                    <img id="west" className="map-location" src={west}></img>
                  </a>
                </li>
                <li className="d-inline">
                  <a id="midwest" className="map-location" onClick={(e) => handleLocationChange(e)}>
                    <img id="midwest" className="map-location" src={midWest}></img>
                  </a>
                </li>
                <li className="d-inline">
                  <a id="northeast" className="map-location" onClick={(e) => handleLocationChange(e)}>
                    <img id="northeast" className="map-location" src={northEast}></img>
                  </a>
                </li>
                <li className="d-inline">
                  <a id="south" className="map-location" onClick={(e) => handleLocationChange(e)}>
                    <img id="south" className="map-location" src={south}></img>
                  </a>
                </li>
                <li className="d-inline">
                  <a id="noncont" className="map-location" onClick={(e) => handleLocationChange(e)}>
                    <img id="noncont" className="map-location" src={nonCont}></img>
                  </a>
                </li>
                <li className="d-inline">
                  <a id="world" className="map-location" onClick={(e) => handleLocationChange(e)}>
                    <img id="world" className="map-location" src={world}></img>
                  </a>
                </li>
              </ul>
                </div>
                )} */}
              <div className="col no-padding">
                <main className="col-md-10 ms-sm-auto col-lg-11 no-padding">
                  <div className="embed">
                    {dashboardId === dashboards["over"] && (
                      <EmbeddedDashboard id={dashboards["over"]} token={token} unauthFn={() => setToken("")} />
                    )}
                    {dashboardId === dashboards["outputsTotal"] && (
                      <EmbeddedDashboard id={dashboards["outputsTotal"]} token={token} unauthFn={() => setToken("")} />
                    )}
                    {dashboardId === dashboards["outputsMfbm"] && (
                      <EmbeddedDashboard id={dashboards["outputsMfbm"]} token={token} unauthFn={() => setToken("")} />
                    )}
                    {dashboardId === dashboards["output"] && (
                      <EmbeddedDashboard id={dashboards["output"]} token={token} unauthFn={() => setToken("")} />
                    )}
                    {dashboardId === dashboards["output_pe"] && (
                      <EmbeddedDashboard id={dashboards["output_pe"]} token={token} unauthFn={() => setToken("")} />
                    )}
                    {dashboardId === dashboards["output_ls"] && (
                      <EmbeddedDashboard id={dashboards["output_ls"]} token={token} unauthFn={() => setToken("")} />
                    )}
                    {dashboardId === dashboards["output_cp"] && (
                      <EmbeddedDashboard id={dashboards["output_cp"]} token={token} unauthFn={() => setToken("")} />
                    )}
                    {dashboardId === dashboards["output_boc"] && (
                      <EmbeddedDashboard id={dashboards["output_boc"]} token={token} unauthFn={() => setToken("")} />
                    )}
                    {dashboardId === dashboards["output_lc"] && (
                      <EmbeddedDashboard id={dashboards["output_lc"]} token={token} unauthFn={() => setToken("")} />
                    )}
                    {dashboardId === dashboards["output_cd"] && (
                      <EmbeddedDashboard id={dashboards["output_cd"]} token={token} unauthFn={() => setToken("")} />
                    )}
                    {dashboardId === dashboards["output_spc"] && (
                      <EmbeddedDashboard id={dashboards["output_spc"]} token={token} unauthFn={() => setToken("")} />
                    )}

                    {dashboardId === dashboards["historyOverview"] && (
                      <EmbeddedDashboard id={dashboards["historyOverview"]} token={token} unauthFn={() => setToken("")} />
                    )}
                    {dashboardId === dashboards["historyRevenue"] && (
                      <EmbeddedDashboard id={dashboards["historyRevenue"]} token={token} unauthFn={() => setToken("")} />
                    )}
                    {dashboardId === dashboards["historyCosts"] && (
                      <EmbeddedDashboard id={dashboards["historyCosts"]} token={token} unauthFn={() => setToken("")} />
                    )}

                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <main className="form-signin w-100 m-auto">
          <div className="signin-wrap">
            <img className="mb-4 signin-logo" src={clientLogo} alt="" width="200" height="150" />
          </div>
          {msLogin && (
            <SignInButton />
          )}


          {!msLogin && (
            <form className="login-form">
              <div className="form-floating">
                <p>Username</p>
                <input type="text" className="form-control" id="floatingInput" placeholder="Username" />
              </div>
              <div className="form-floating">
                <p>Password</p>
                <input type="password" className="form-control" id="floatingPassword" placeholder="Password" />
              </div>


              <button
                className="w-100 btn btn-lg client-button"
                type="submit"
                onClick={(e) => handleLogin(e)
                }
              >
                Sign in
              </button>
              <br />
              <br />
              {errmsg.length > 0 && (
                <div className="alert alert-danger" role="alert">
                  {errmsg}
                </div>
              )}
            </form>
          )}
          <div className="login-powered" onDoubleClick={() => setMsLogin(!msLogin)}>
            <div className="text">Powered by</div>
            <img src={fastloopLogo} />
          </div>
        </main>
      )}
    </div>
  );
};
