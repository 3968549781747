import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import mslogo from "../assets/mslogo.png"

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.error(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.error(e);
            })
        }
    }
    return (
        <div>
            <button
                className="w-100 btn btn-lg client-button"
                onClick={(e) => {
                    e.preventDefault();
                    handleLogin("redirect")
                }
                }
            >
                <img src={mslogo}></img>&nbsp;
                Sign in with Microsoft account
            </button>

        </div>
    )
}
