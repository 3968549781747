import styled from "styled-components";

export const EmbedContainer = styled.div`
  width: 100%;
  height: 90vh;
  & > iframe {
    width: 100%;
    height: 100%;
  }
`;
